@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../pages/arcade.module';

$color-arcade-hover-purple: #61339E;
$color-arcade-shadow: #148686;;
$color-slide-text-bg: rgba(255, 255, 255, 0.8);

.wrapper {
  margin-top: 30px;
  margin-bottom: 60px;
}

.sliderWrapper {
  padding: 30px;
  background-color: $color-white;
  text-align: center;
  border-radius: 50px;
  margin-bottom: 0;
}

@include breakpoint('md') {
  .zoneWrapperDesktop {
    background-color: $color-white;
    text-align: center;
    border-radius: 50px;
    box-shadow: 10px 10px 0 0 $color-arcade-shadow;
    border: 4px solid #20CCCC;

    .sliderWrapper {
      box-shadow: none;
      background-color: transparent;
    }

    :global(.react-slideshow-wrapper .images-wrap) {
      flex-wrap: nowrap;
      width: 100% !important;

      & > div {
        flex-shrink: 0;
        width: 100% !important;
      }
    }
  }
}

.arcadeHeader {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.slider {
  position: relative;
  margin: auto;

  .slideContent {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .imageWrapper {
    flex: 0 0 auto;
    margin-right: 20px;

    img {
      object-fit: cover;
      width: 100%;
      max-width: 380px;
      height: 200px;
      border-radius: 10px;
    }
  }

  .textWrapper {
    flex: 1;
    background: $color-slide-text-bg;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
  }

  .nextGenGames {
    font-size: $text-6;
    color: $color-feed-purple;
  }

  .title {
    color: $color-black;
    font-size: $text-3b;
    text-transform: uppercase;
    margin-bottom: $space-2;
  }

  .description {
    font-size: $text-5a;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 60px;
}

.arrowsContainer {
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  padding-top: 10px;
}

.nav {
  color: $color-white;
  border: none;
  padding: 7px;
  cursor: pointer;
  z-index: 1;
  margin-right: 10px;
  background: $color-arcade-link-purple;
  
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  &:hover {
    background: $color-arcade-hover-purple;
  }
  svg {
    position: relative;
    left: 2px;
    width: 20px;
    fill: $cyan-main;
  }
}

.nav:first-of-type {
  svg {
    position: relative;
    left: -2px;
  }
}



.dotsContainer {
  margin-left: -110px;
  display: flex;
  justify-content: center;
  flex-grow: 1;

  @include breakpoint('md') {
    margin-left: -110px;
  }
}

.dot {
  background: $color-arcade-hover-purple;
  border: none;
  padding: 5px;
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: $color-arcade-link-purple;
  }

  &.active {
    background: transparent;
    position: relative;

    &::after {
      content: " ";
      top: -5px;
      left: -2px;
      position: absolute;
      width: 15px;
      height: 19px;
      background-image: url('../../../static/img/arcade-sparkle.svg');
      background-size: cover;
    }
  }
}

.mobileSlider {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding-left: $space-2;
  overscroll-behavior-x: contain;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mobileSlide {
  width: 100%;
  flex: 0 0 auto;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.imagePlaceholder {
  aspect-ratio: 3/2;
}

@include breakpoint('md') {
  .mobileSlide {
    margin-right: $space-4;
  }
}

@include breakpoint('md', 'max') {
  .wrapper {
    margin: 0 -1rem 20px;

    :global(.react-slideshow-wrapper.slide) {
      overflow: visible;
    }

    .arcadeHeader {
      margin: 0 $space-2;
    }
  }

  .sliderWrapper {
    box-shadow: 10px 10px 0 0 $color-arcade-shadow;
    border: 4px solid #20CCCC;
    margin-right: 20px;
    border-radius: 25px;
  }

  .mobileSlider {
    padding: $space-2 $space-2 $space-4 $space-2;
  }

  .slider {
    .title {
      font-size: $text-4a;
    }

    .slideContent {
      flex-direction: column;
    }

    .textWrapper {
      padding: 10px 0;
    }

    .imageWrapper {
      width: 100%;
      margin-right: 0;

      img {
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: none;
      }
    }
  }

  .controls {
    display: none;
  }

  .mobileSlide {
    width: 80%;
  }
}

