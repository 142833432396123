@import '../styles/variables';
@import '../styles/mixins';
@import '../components/EmojiReactions/emojiReactions.module';

$game-shadow-color: #148686;
$arcade-border-color: #20CCCC;
$arcade-heading-color: #444;

// arcade page background and main content padding

.pageContainer {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $cyan-main;
  background-image: url('../static/img/arcade-background-mobile.svg');
  background-repeat: repeat-x;
  background-size: 100%;
  background-position: top;
  @include breakpoint('sm') {
    background-image: url('../static/img/arcade-background-desktop.svg');
  }
}
.arcadeWrapper {
  max-width: calc($content-max-width + $space-4);
  margin: auto;
  padding: 0 $space-2;

  h1, h2 {
    text-transform: uppercase;
    font-family: mikrobe-variable, sans-serif;
    font-variation-settings: 'wdth' 125;
  }

  h3, h4, h5, h6 {
    text-transform: uppercase;
    font-family: antique-olive-compact, sans-serif;
  }

  @include breakpoint('sm') {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}

// more fun zone flex unit rendering

.noWidget {
  @include breakpoint('sm') {
    height: 28px;
  }
}

.flexWidgetWRapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.widget33 {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 0 $space-3b 0;

  @include breakpoint('sm') {
    margin: $space-3b 0;
    width: 33%;
  }
  article {
    text-align: center;
    border-radius: 10px;
    border: 4px solid $cyan-main;
    padding-bottom: 28px;

    @include breakpoint('sm') {
      min-height: 734px;
    }

    header {
      text-align: left;
      margin-right: 25px;
      padding-top: 50px;
      padding-left: 45px;
      padding-right: 25px;
      padding-bottom: 25px;
      background-image: url('../static/img/arcade-sparkles.svg');
      background-repeat: no-repeat;
      background-position: right center;
    }
    header > h1 {
      text-transform: uppercase;
      font-family: antique-olive-compact, sans-serif;
      font-size: $text-4;
      color: $color-black;
    }
    header > h2 {
      text-transform: uppercase;
      font-family: antique-olive-compact, sans-serif;
      font-size: $text-5;
      color: $color-arcade-header-pink;
    }

    [class^='emojiReactions_reactions'] {
      @extend .reactions;
      margin-left: auto;
      margin-right: auto;
    }
    [class^='emojiReactions_dialog'] {
      left: -140px
    }

    div > button {
      width: 90%;
      font-family: antique-olive, sans-serif;
      font-size: $text-6a;
      color: $color-arcade-link-purple;
      margin-top: $space-1;
      position: relative;
      text-align: center;
      padding-left: $space-3;

      &::before {
        content: '';
        position: absolute;
        left: calc(50% - 70px);
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 13.087C3.23858 13.087 1 10.8094 1 8C1 6.00268 2.13142 4.27421 3.77778 3.44182M6 13.087L4.61111 11.1087M6 13.087L4.05556 14.5M6 2.91304C8.76142 2.91304 11 5.19055 11 8C11 9.99732 9.86858 11.7258 8.22222 12.5582M6 2.91304L7.94444 1.5M6 2.91304L7.38889 4.8913' stroke='%23481F6B' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    // apply feed-content-area button style
    button {
      background: none;
      border: none;
    }

  }

}

.content {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  //justify-content: space-between;
  @include breakpoint('sm') {
    margin: $space-3b 0;
  }
  &:not(.content66) {
    margin-top: 0;
  }
  &.content66 {
    margin-bottom: 0;
    width: 100%;
    @include breakpoint('sm') {
      width: 66%;
    }
    .contentWrapper {
      flex: 0 0 50%;
    }
    .contentWrapper:nth-child(2),
    .contentWrapper:nth-child(4) {
      padding-left: 4px;
    }
  }

  :global(.ad-placeholder-feed-story) {
    flex: 0 0 100%;
    margin-top: 1.5rem;
  }
  // more fun content card rendering

  .contentWrapper {
    flex: 0 0 50%;
    @include breakpoint('sm') {
      flex: 0 0 33.33%;
    }
    [class^='singleCard_feedArticle'] {
      display: block;
    }
    [class^='singleCard_thumbnailWrapper'] {
      display: block;
      max-width: calc(100% - 10px);
      @include breakpoint('sm') {
        max-width: calc(100% - 36px);
      }
    }
    [class^='singleCard_textWrapper'] {
      max-width: calc(100% - 10px);
      @include breakpoint('sm') {
        max-width: calc(100% - 56px);
      }
      display: block;
      margin-top: 0;
      margin-left: 0;
    }
    [class^='singleCard_heading'] {
      -webkit-line-clamp: 5 !important;
      line-clamp: 5 !important;
      text-transform: none;
      &:hover {
        color: $color-feed-purple;
      }
      font-size: $text-6;
      @include breakpoint('sm') {
        font-size: $text-5a;
      }
      font-family: antique-olive, Helvetica, Arial, sans-serif;
      font-weight: 400;
      color: $arcade-heading-color;
      div {
        @include breakpoint('sm') {
          width: 100%;
        }
        width: 145px;
        font-size: $text-4b;
        text-transform: none;
        font-family: antique-olive-compact, sans-serif;
        font-weight: 700;
        margin-bottom: 4px;
      }
    }
    [class^='singleCard_statsWrapper'] {
      display: none;
    }

    [class^='feedCard_info'] {
      margin: 2px 0 4px 0;
      font-size: $text-7a;
      @include breakpoint('sm') {
        font-size: $text-5a;
      }
    }

    [class^='feedCard_tag'] {
      font-family: antique-olive, sans-serif;
      margin-top: 8px;
      margin-bottom: 0;
      color: $color-arcade-link-purple;
      svg:first-of-type {
        display: none;
      }
    }
  }

  .smallIcon {
    height: 24px;
    width: 24px;
  }
}

// content zone wrappers

.zoneWrapper {
  position: relative;
  border-radius: 25px;
  background: $color-white;
  padding: calc($space-2 - 2px);
  margin-bottom: calc($space-2 - 2px);
  box-shadow: 10px 10px 0 0 $game-shadow-color;
  border: 4px solid $arcade-border-color;

  @include breakpoint('sm') {
    border-radius: 50px;
    background: $color-white;
    padding: $space-3;
  }
}

.zoneWrapper:first-of-type {
  margin-bottom: $space-3;
}

// common header rendering

.arcadeHeader {
  text-transform: uppercase;
  font-family: mikrobe-variable, sans-serif;
  font-variation-settings: 'wdth' 125;
}

h2.arcadeHeader {
  font-size: $text-2;
  @include breakpoint('sm') {
    margin-top: 14px;
    font-size: $text-05a;
  }
}

h3.arcadeHeader {
  font-size: $text-4a;
  @include breakpoint('sm') {
    font-size: $text-3b;
  }
}

.adStory {
  margin-bottom: $space-3b;
}

// utilities

.clearFix:after {
  content: "";
  display: table;
  clear: both;
}
