@import '../../../pages/arcade.module';

$game-border-radius: 25px;
$game-shadow-color: #148686;
$streak-color: #FF4040;
$game-background: $color-white;
$game-title-color: $color-black;

@mixin responsive($breakpoint) {
  @if $breakpoint == tablet {
    @include breakpoint('md', 'max') { @content; }
  } @else if $breakpoint == mobile {
    @include breakpoint('sm', 'max') { @content; }
  }
}

.dailyGamesZoneWrapper {
  margin-bottom: 14px;
}

.header {
  display: flex;
  gap: $space-2;
  align-items: flex-end;
  margin-bottom: $space-3a;
}

.games {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $space-4;
}

.game {
  padding: $space-3;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $game-background;
  text-align: center;
  border-radius: $game-border-radius;
  transition: transform 0.3s ease-out;
  box-shadow: 10px 10px 0 0 $game-shadow-color;
  border: 4px solid #20CCCC;

  &:focus-within {
    outline: 2px solid $game-title-color;
    outline-offset: 2px;
  }

  a {
    width: 100%;

    &:hover, &:focus {
      outline: none;
      text-decoration: none;
    }
  }

  h2.gameTitle {
    text-transform: uppercase;
    font-family: antique-olive-compact, sans-serif;
    font-size: $text-3b;
    color: $game-title-color;
  }

  .imgWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
  }

  .gameImage {
    margin-bottom: $space-1;
    height: 136px;
    object-fit: contain;
  }

  .titleWrapper {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .gameTitle {
    width: 100%;
    text-align: center;
  }

  &.bobAnimation,
  &:hover {
    animation: bob 2s infinite ease-in-out;
  }
}

.streak {
  position: absolute;
  font-family: antique-olive-compact, sans-serif;
  font-size: $text-3b;
  top: 10px;
  right: 10px;
  margin-left: $space-2;
  color: $streak-color;

  svg {
    margin-bottom: -4px;
  }
}

.highlighted {
  overflow: visible;
  position: absolute;
  top: -40px;
  left: -35px;
  display: inline-block;
  padding: 5px 10px;
  border: 3px solid #FDD603;
  border-radius: 10px;
  box-shadow: 3px 3px 0 #333333;
  font-family: antique-olive-compact, sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #000000;
  background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%22102%22 height%3D%2232%22 viewBox%3D%220 0 102 32%22 fill%3D%22none%22%3E%3Crect x%3D%220%22 y%3D%220%22 width%3D%22102%22 height%3D%2232%22 fill%3D%22%23DEBF19%22/%3E%3Cpath d%3D%22M87.5 0H101.5L85.5 32H71.5L87.5 0Z%22 fill%3D%22%23FCE777%22/%3E%3Cpath d%3D%22M39 0H82.5L66.5 32H23L39 0Z%22 fill%3D%22%23FCE777%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  span {
    position: relative;
    z-index: 1;
  }
}

.highlighted::before,
.highlighted::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none"><path d="M5.03368 11.2769C5.11094 11.3151 5.16589 11.3752 5.19232 11.4444L7.14622 16.7874C7.24991 17.0698 7.75008 17.0698 7.85378 16.7874L9.80767 11.4444C9.83207 11.3752 9.88906 11.3151 9.96632 11.2769L14.8277 8.8358C15.0574 8.72104 15.0574 8.45143 14.8277 8.33667L9.96632 5.89565C9.88906 5.85739 9.83411 5.79729 9.80767 5.72807L7.85378 0.385109C7.75008 0.10275 7.24991 0.10275 7.14622 0.385109L5.19232 5.72807C5.16793 5.79729 5.11094 5.85739 5.03368 5.89565L0.172315 8.33667C-0.0574385 8.45143 -0.0574385 8.72104 0.172315 8.8358L5.03368 11.2769Z" fill="%23DEBF19"/></svg>');
}

.highlighted::before {
  top: -15px;
  left: -15px;
  animation: fade 2s infinite 1.5s;
}

.highlighted::after {
  bottom: -18px;
  right: -18px;
  animation: fade 2s infinite;
}

@keyframes fade {
  0%, 33%, 100% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}


// Animations
@keyframes bob {
  0% {
    transform: translate3d(-5%, -2%, 0) scale(1.04) rotate(-5deg);
  }
  50% {
    transform: translate3d(5%, -2%, 0) scale(1.04) rotate(5deg);
  }
  100% {
    transform: translate3d(-5%, -2%, 0) scale(1.04) rotate(-5deg);
  }
}

// Reduced motion preferences
@media (prefers-reduced-motion: reduce) {
  .game {
    &.bobAnimation,
    &:hover {
      animation: none;
    }

    .gameImage {
      transition: none;
    }
  }
}

// Breakpoints
@include responsive(tablet) {
  .header {
    align-items: flex-start;
    flex-direction: column;
  }

  .games {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-2;
  }
}

@include responsive(mobile) {
  .header {
    gap: $space-1;
    margin-top: $space-1;
  }

  .highlighted {
    top: -20px;
    left: -25px;
  }

  .gameTitle {
    position: relative;

    div {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .streak {
    font-size: $text-6a;

    svg {
      height: 16px !important;
      width: 15px !important;
    }
  }

  .game {
    padding: $space-2;

    h2 {
      font-size: $text-5;
    }

    .gameImage {
      height: 96px;
    }
  }
}

// Print styles
@media print {
  .game {
    break-inside: avoid;

    .gameImage {
      max-width: 100%;
      height: auto;
    }
  }

  .bobAnimation {
    animation: none;
  }
}

