@import '../../../styles/mixins';
@import '../../../pages/arcade.module';


.headerWrapper {
  display: flex;
  justify-content: space-between;
  max-width: calc($content-max-width + $space-4);
  margin: auto;
  padding:  20px $space-2 14px $space-2;

  :global([class^="subscriptionButton_subscribeButton__"]) {
    background-color: $color-white;
  }

  :global(#expansionContent) {
    right: 0;
    top: 65px
  }

  .arcadeLogo {
    height: 45px;
    width: 156px;
    @include breakpoint('sm') {
      height: 73px;
      width: 253px;
    }
  }

  @include breakpoint('sm') {
    padding: 32px 16px;
  }
}
