@import '../../../pages/arcade.module';

.timer {
  display: flex;
  align-items: flex-end;
  font-family: antique-olive, sans-serif;
  color: $color-arcade-link-gray;
  margin-bottom: $space-1a;
}

.timer span {
  font-size: 1rem;
  margin-right: 2px;
}

.time {
  display: flex;
  align-items: center;
  padding-left: $space-05;
}

.timeSegment {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0 2px;
}

.timeSegment span {
  font-family: antique-olive, sans-serif;
  font-size: $text-5;
  font-weight: $bold;
}

.label {
  font-size: $text-6;
}
