@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../pages/arcade.module';

.mobileMenu {
  margin-right: 10px;
  float: right;
  @include breakpoint('sm') {
    display: none;
  }
}

.navigationFilters {
  display: none;
  @include breakpoint('sm') {
    display: inline-block;
    border-radius: 20px;
    background: $cyan-light;
    margin-top: -8px;
    float: right;
  }
  &.menuOpen {
    position: absolute;
    z-index: 999;
    top: 18px;
    right: 24px;
    display: inline-block;
    background: white;
    width: 200px;
    border: 1px solid black;
    padding: 8px;
    ul {
      li {
        font-size: $text-5;
        padding: 4px 6px;
        text-align: left;
        width: 100%;
        display: block;
        svg {
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-bottom: -4px;
          margin-right: 10px;
        }
      }
    }
  }

  ul {
    padding: 0 !important;
    li {
      color: $color-arcade-link-gray;
      font-size: $text-5;
      text-transform: uppercase;
      font-family: mikrobe-variable, sans-serif;
      font-variation-settings: 'wdth' 110;
      text-align: center;
      display: inline-block;
      margin-right: 20px;
      padding: 0;
      list-style: none;
      svg {
        padding-bottom: 4px;
        margin: 0 auto;
        display: block;
        transition: transform 0.3s ease-in-out;
      }
      &.active {
        color: $color-arcade-link-purple;
        @include breakpoint('sm') {
          text-decoration: underline 2px;
        }
      }
      &.disabled {
        display: none;
        pointer-events: none;
      }

      &:hover {
        cursor: pointer;
        svg {
          transform: scale(1.12);
        }
      }
    }
    li:last-of-type {
      margin: 0;
    }

  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 998;
}
